/* Kontainer untuk seluruh form login */
.logincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(45deg, #4e73df, #1cc88a); /* Background gradasi */
  }
  
  /* Gaya untuk form login */
  .loginform {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Judul login */
  .loginform h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Gaya untuk setiap grup input */
  .inputgroup {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .inputgroup label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .inputgroup input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  .inputgroup input:focus {
    border-color: #4e73df; /* Warna border saat fokus */
    outline: none;
  }
  
  /* Gaya untuk pesan error */
  .errormessage {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  /* Gaya untuk tombol login */
  .btnlogin {
    background-color: #4e73df;
    color: #fff;
    font-size: 16px;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  /* Efek hover pada tombol login */
  .btnlogin:hover {
    background-color: #2e59d9;
  }
  
  .btnlogin:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  /* Animasi responsif untuk form */
  @media (max-width: 480px) {
    .loginform {
      padding: 30px;
    }
  
    .inputgroup input {
      font-size: 14px;
    }
  
    .btnlogin {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  