/* Gallery.module.css */
.previewContainer {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .previewImage {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .input {
    padding: 10px;
    margin-right: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 45%;
  }
  
  .button {
    padding: 12px 25px;
    font-size: 16px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
  
  .galleryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .galleryItem {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .galleryImage {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .actions {
    margin-top: 20px;
  }
  
  .editButton {
    background-color: #f39c12;
    margin-right: 10px;
  }
  
  .deleteButton {
    background-color: #e74c3c;
  }
  
  .editButton:hover {
    background-color: #e67e22;
  }
  
  .deleteButton:hover {
    background-color: #c0392b;
  }
  
  @media (max-width: 768px) {
    .form {
      flex-direction: column;
      align-items: center;
    }
  
    .input {
      width: 80%;
      margin-bottom: 10px;
    }
  
    .button {
      width: 80%;
    }
  }
  