.container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  
  .header {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 14px;
    color: #555;
  }
  
  .input, .textarea, .fileInput {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .textarea {
    min-height: 150px;
    resize: vertical;
  }
  
  .submitButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }
  
  .subheader {
    margin-top: 40px;
    font-size: 20px;
    color: #333;
  }
  
  .list {
    list-style: none;
    padding: 0;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .textContainer {
    flex-grow: 1;
    margin-left: 15px;
  }
  
  .listTitle {
    font-size: 18px;
    color: #333;
  }
  
  .listParagraph {
    font-size: 14px;
    color: #555;
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .actionButton {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .editButton {
    background-color: #4CAF50;
    color: white;
  }
  
  .deleteButton {
    background-color: #f44336;
    color: white;
  }
  
  .previewContainer {
    margin-top: 10px;
    text-align: center;
  }
  
  .previewImage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  