.adminText {
  color: #3498db; /* Light blue color for "Admin" */
}

.dashboardText {
  color: #16a085; /* Greenish color for "Dashboard" */
}
General Styles
.homeAdmin {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f7f8fa; /* Soft light gray background */
  font-family: 'Roboto', sans-serif;
}
/* Sidebar utama */
.sidebar {
  width: 250px;
  background-color: #ffffff; /* Bright white background */
  color: #2c3e50; 
  padding: 20px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
  border-radius: 8px 0 0 8px; 
}

.sidebarHeader h2 {
  font-size: 24px;
  color: rgb(8, 6, 6);
}

.sidebarLinks ul {
  list-style-type: none;
  padding: 0;
}

.sidebarLink {
  color: rgb(33, 29, 29);
  text-decoration: none;
  display: block;
  padding: 10px;
  margin: 5px 0;
}

.sidebarLink:hover {
  background-color: #b5bdb3;
}

/* Dropdown */
.dropdown {
  position: relative; /* untuk memungkinkan submenu ditampilkan dengan absolute positioning */
}

/* Mengubah arah dropdown jika perlu */
.arrow {
  margin-left: 5px;
}

/* Submenu tersembunyi secara default */
.subMenu {
  display: none; /* Submenu disembunyikan */
  padding-left: 20px;
}

/* Menampilkan submenu saat dropdown di-hover */
.dropdown:hover > .subMenu {
  display: block; /* Menampilkan submenu saat hover pada .dropdown */
}

/* Gaya untuk submenu */
.subMenu li {
  margin: 10px 0;
}

.sidebarSubLink {
  color: rgb(12, 10, 10);
  text-decoration: none;
  display: block;
  padding: 10px;
  margin: 5px 0;
}

.sidebarSubLink:hover {
  background-color: #9ba1a6;
}
