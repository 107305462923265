/* homeadmin.module.css */

/* General Styles for HomeAdmin */
.homeAdmin {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: #f5f7fa; /* Light background for the app */
    font-family: 'Arial', sans-serif;
  }
  
/* Sidebar Styling */
.sidebar {
    width: 250px;
    background-color: #b7c0c6; /* Dark blue-gray background */
    color: rgb(21, 12, 12);
    padding: 30px 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .sidebarHeader {
    text-align: center;
    font-size: 24px;
    color: #081013;
    margin-bottom: 30px;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
  }
  
  /* Styling tombol kotak untuk setiap link */
  .sidebarLinks {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebarLinks li {
    margin: 20px 0;
  }
.sidebarLinks li a {
    display: block;
    padding: 15px 20px;
    background-color: #34495e; /* Warna tombol */
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
  }
  
  /* Efek hover */
  .sidebarLinks li a:hover {
    background-color: #16a085; /* Warna saat hover */
    transform: translateY(-3px); /* Sedikit efek angkat */
  }
  

/* Dropdown item Styling */
.sidebarLinks li ul li a {
    padding: 12px 20px;
    text-align: left;
    background-color: #34495e;
    color: white;
    font-size: 16px;
    font-weight: normal;
    border-radius: 8px;
  }
  
  /* Efek hover pada item submenu */
  .sidebarLinks li ul li a:hover {
    background-color: #16a085;
  }
  
  /* Efek untuk menambahkan garis pada sisi kiri tombol saat hover */
  .sidebarLinks li a:hover::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #16a085;
    border-radius: 0 5px 5px 0;
    animation: expand 0.3s ease;
  }
  
  @keyframes expand {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }
  
  /* Sidebar Responsiveness */
  @media (max-width: 1024px) {
    .sidebar {
      width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 180px;
      padding: 25px 15px;
    }
  
    .sidebarHeader {
      font-size: 20px;
    }
  
    .sidebarLinks li a {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .sidebar {
      width: 60px;
      padding: 20px 10px;
    }
  
    .sidebarHeader {
      font-size: 18px;
    }
  
    .sidebarLinks li a {
      font-size: 14px;
      padding: 10px 5px;
    }
  
    .sidebarLinks li a i {
      font-size: 18px;
    }
  }
  
  /* Add transition effect to the sidebar */
  .sidebarLinks li a {
    transition: background-color 0.2s ease, transform 0.3s ease;
  }
  
  /* Main Content Area */
  .mainContent {
    margin-left: 250px;
    padding: 30px;
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    overflow-y: auto;
    background-color: #ecf0f1; /* Light gray background for the content */
  }
  
  h1, h2, h3 {
    color: #333; /* Darker text color for headings */
  }
  
  h2 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  p {
    color: #666;
    line-height: 1.6;
  }
  
  /* Smooth scrolling for the content */
  .mainContent {
    scrollbar-width: thin;
    scrollbar-color: #3498db #ecf0f1;
  }
  
  .mainContent::-webkit-scrollbar {
    width: 6px;
  }
  
  .mainContent::-webkit-scrollbar-thumb {
    background-color: #3498db;
    border-radius: 5px;
  }
  
  .mainContent::-webkit-scrollbar-track {
    background-color: #ecf0f1;
  }
  
  /* Sidebar responsiveness */
  @media (max-width: 1024px) {
    .sidebar {
      width: 200px; /* Shrink sidebar for medium screens */
    }
  
    .mainContent {
      margin-left: 200px;
    }
  }
  
  @media (max-width: 768px) {
    /* Full screen navigation for mobile */
    .homeAdmin {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
  
    .mainContent {
      margin-left: 0;
    }
  }
  
  @media (max-width: 480px) {
    /* Collapse the sidebar completely on small screens */
    .sidebar {
      width: 60px;
      padding: 20px 10px;
      transition: width 0.3s ease;
    }
  
    .sidebarHeader {
      font-size: 18px;
    }
  
    .sidebarLinks li a {
      font-size: 14px;
      padding: 10px;
    }
  
    .mainContent {
      margin-left: 0;
    }
  }
  
  /* Hover effects for buttons and links in the main content */
  button, a {
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
  }
  
  button:hover, a:hover {
    background-color: #2980b9;
    transform: scale(1.05);
  }
  
  /* Additional content styling */
  .card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .card-body {
    color: #555;
  }
  
  /* Modal/Popup Styling */
  .modal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none; /* Hidden by default */
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal.show {
    display: flex;
  }
  
  .modal-header {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .modal-footer {
    margin-top: 20px;
    text-align: right;
  }
  
  .modal-footer button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-footer button:hover {
    background-color: #2980b9;
  }
  